import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          "Home": "Home",
          "About": "About Us",
          "Values": "Our Values",
          "Services": "Services",
          "Projects": "Projects",
          "Galery": "Galery",
          "Welcome": "Welcome",
          "WelcomeDescription": "We appreciate you taking the time to go through the VIA company profile. This catalogue is designed to provide you with an introduction of VIA, our services and our key competitive strengths. We hope and look forward to meeting our clients personally to develop a relationship through one-on-one interaction shortly.",
          "SecondWelcomeDescription": "Since our establishment in 2014, we have developed an entrusted and reliable construction company by building long-lasting relationships with our clients, employees, suppliers and contractors. We thank all those who have contributed to the development of VIA over the years, and we thank you for your interest in our company and look forward to working with you",
          "Whoweare": "Who we are",
          "FirstAboutUsDescription": "VIA is a construction company established in 2014. The name of the company is derived from the Latin word VIA which means; road or direction, something which at the same time represents the vision of the company: „Road to the future“.",
          "SecondAboutUsDescription": "VIA is a privately-owned company founded after 16 years of experience as part of EUROKOS, a company which remains our main partner today. The head office of VIA is located in Gërlicë, Ferizaj, RKS. With our experience in construction, maintenance, road signalling and production projects, we have been able to gain credibility and appreciation in a short time. Since our beginnings in 2014, we have implemented projects that have left behind a trail of success. Vision, courage and expertise are the key elements of success in all the areas we operate.",
          "Ourvalues": "OUR VALUES",
          "Ethics": "Ethics",
          "EthicsDescription": "We at VIA remain true to our founding values of providing qualitative projects through honest and hard work. We try to adhere to the highest ethical standards in the industry and we have been recognized by the construction industry as a trustworthy business by being consistently associated with integrity, high standards and quality of service and personal attention to clients.",
          "Staff": "Staff",
          "StaffDescription": "We recognize that our primary asset is our people, and that a truly successful company needs to be a rewarding work environment for its staff.Our company is structured and managed to provide ample opportunities and encouragement for our team to reach their optimum potential. The same core of management staff, engineers, technicians and field workers who have developed together with the company since its inception and who have grown over the years, has made it possible for the company to develop fast and efficiently. We believe that mutual respect forms the base of our success.",
          "Service": "Services",
          "FirstService": "Roads and Bridges",
          "SecondService": "SUMMER & WINTER MAINTENANCE",
          "ThirdService": "Road Signage",
          "FourthService": "Construction",
          "FifthService": "Production",
          "CoreWork": "Core Work",
          "Safety": "Safety",
          "Improvement": "Improvement",
          "SafetyDescription": "Safety is a responsibility of every employee. At VIA we understand that accidents are prevented through cautious planning, appropriate training, and cooperative efforts in all areas of our operations. All our work is performed in the safest possible manner.",
          "ImprovementDescription": "VIA has a tradition of fine performance, founded on a commitment to performing construction within budget and according to specifications. The Company has established itself as one of the most highly regarded companies by continually improving the quality of its work product and opportunities that are available for our employees.",
          "SecondImprovementDescription": "The desire and commitment to improve on everything we do here is of the utmost importance to VIA. This unwavering dedication towards progression is what broadens the focus of our business and allows our employees to thoroughly examine every aspect of their performance at VIA.",
          "ThirdImprovementDescription": "Through this process, we remain steadfast in our tradition to deliver superior performance, attention to safety issues, satisfaction to our customers and fulfilment for our employees.",
          "ProjectsPortfolio": "Projects Portfolio",
          "ProjectName": "Project Name",
          "Contractor": "Contractor",
          "Period": "Period",
          "Amount": "Amount",
          "ProjectGalery": "Projects Galery",

          "1stProjectName": "CONSTRUCTION OF ROADS, PLATFORMS, CONNECTING ROADS, STAGE II AND III, KITKA, (WIND POWER FARM)",
          "1stContractorName" : "NTP EUROKOS shpk",
          "1stProjectPeriod": "2016 - 2018",
          "1stProjectAmount": "394,000.00",

          "2ndProjectName": "CONSTRUCTION OF THE HYDROPOWER PLANT BRODI 3, DRAGASH",
          "2ndContractorName": "NTP EUROKOS shpk",
          "2ndProjectPeriod": "2018 - 2019",
          "2ndProjectAmount": "414,000.00",

          "3rdProjectName": "CONSTRUCTION OF CONNECTING ROADS AND OPENING OF THE UNDERGROUND CHANNEL FOR THE EXTENSION OF HIGH VOLTAGE CABLE FOR THE HYDROPOWER PLANT LEPENCI 3",
          "3rdContractorName": "HIDROENERGJI shpk",
          "3rdProjectPeriod": "2018 - 2019",
          "3rdProjectAmount": "344.000.00",

          "4thProjectName": "EXPANSION OF THE NATIONAL ROAD N 9.1, SEGMENT DOLLC - GJAKOVË - LOT III",
          "4thContractorName": "MINISTRY OF INFRASTRUCTURE & TRANSPORT",
          "4thProjectPeriod": "2019 - 2021",
          "4thProjectAmount": "4,393,000.00",

          "5thProjectName": "REHABILITATION OF THE SLIDE IN HANI I ELEZIT - GORANCË STREET",
          "5thContractorName": "MINISTRY OF INFRASTRUCTURE & TRANSPORT",
          "5thProjectPeriod": "2019 - 2020",
          "5thProjectAmount": "1,514,000.00",

          "6thProjectName": "CONSTRUCTION OF DIFFERENT MILITARY OBJECTS, CONSTRUCTION OF THE ANNEX OF THE OBJECT OF PCAB IN THE BARRACKS OF KSF IN POMOZOTIN",
          "6thContractorName": "MINISTRY OF DEFENCE",
          "6thProjectPeriod": "2019 - 2020",
          "6thProjectAmount": "223,000.00",

          "7thProjectName": "RECONSTRUCTION OF HOUSING UNITS (BUILDINGS) FOR THE NEW DEVELOPMENT AREA VORA",
          "7thContractorName": "ALBANIAN DEVELOPMENT FUND",
          "7thProjectPeriod": "2021 - 2024",
          "7thProjectAmount": "3,250,000.00",

          "8thProjectName": "REHABILITATION OF QAMIL ILAZI AND SALI BAJRA STREETS",
          "8thContractorName": "MUNICIPALITY OF KAÇANIK",
          "8thProjectPeriod": "2021 - 2022",
          "8thProjectAmount": "259,000.00",

          "9thProjectName": "CONSTRUCTION OF GABIONS ON THE ROAD MITROVICA - SHALË E BAJGORËS",
          "9thContractorName": "TALI SHPK",
          "9thProjectPeriod": "2021 - 2022",
          "9thProjectAmount": "69,000.00",

          "10thProjectName": "CONSTRUCTION OF MILITARY SHOOTING RANGES IN THE KSF BARRACKS IN FERIZAJ",
          "10thContractorName": "MINISTRY OF DEFENCE ",
          "10thProjectPeriod": "2022 - 2022",
          "10thProjectAmount": "144,000.00",

          "11thProjectName": "THE CONSTRUCTION OF THE FACILITY FOR THE MUNICIPAL ENTERPRISE",
          "11thContractorName": "MUNICIPALITY OF SKENDERAJ",
          "11thProjectPeriod": "2022 - 2023",
          "11thProjectAmount": "248,000.00",

          "12thProjectName": "THE CONSTRUCTION OF SPORTS FIELDS IN THE VILLAGES OF VAJNIKË AND LUBAVEC WITHIN THE PREMISES OF THE VILLAGE SCHOOLS",
          "12thContractorName": "MUNICIPALITY OF SKENDERAJ",
          "12thProjectPeriod": "2022 - 2022",
          "12thProjectAmount": "59,000.00",

          "13thProjectName": "SUMMER AND WINTER MAINTENANCE OF NATIONAL AND REGIONAL ROADS OF KOSOVO,REGION FERIZAJ 1",
          "13thContractorName": "MINISTRY OF INFRASTRUCTURE & TRANSPORT",
          "13thProjectPeriod": "2014 - 2018",
          "13thProjectAmount": "3,032,000.00",

          "14thProjectName": "SUMMER AND WINTER MAINTENANCE OF NATIONAL AND REGIONAL ROADS OF KOSOVO, REGION FERIZAJ 1",
          "14thContractorName": "MINISTRY OF INFRASTRUCTURE & TRANSPORT",
          "14thProjectPeriod": "2018 - 2021",
          "14thProjectAmount": "3,650.000.00",

          "15thProjectName": "SUMMER AND WINTER MAINTENANCE OF LOCAL ROADS",
          "15thContractorName": "MUNICIPALITY OF KAÇANIK",
          "15thProjectPeriod": "2021 - 2022",
          "15thProjectAmount": "260,000.00",

          "16thProjectName": "TRAFFIC MANAGMENT IN THE NATIONAL ROAD N2, KAÇANIK - HANI I ELEZIT",
          "16thContractorName": "MINISTRY OF INFRASTRUCTURE",
          "16thProjectPeriod": "2021 - 2023",
          "16thProjectAmount": "70,100.00",

          "17thProjectName": "SUMMER AND WINTER MAINTENANCE OF NATIONAL HIGHWAY „ARBËR XHAFERI“ LOT 3",
          "17thContractorName": "MINISTRY OF INFRASTRUCTURE",
          "17thProjectPeriod": "2022 - 2025",
          "17thProjectAmount": "2,100.000.00",

          "18thProjectName": "SUMMER AND WINTER MAINTENANCE OF NATIONAL AND REGIONAL ROADS OF KOSOVO, REGION FERIZAJ 1 & GJILAN 2",
          "18thContractorName": "MINISTRY OF INFRASTRUCTURE",
          "18thProjectPeriod": "2022 - 2026",
          "18thProjectAmount": "4,180.000.00",

          "19thProjectName": "VARIOUS PROJECTS FOR VERTICAL AND HORIZONTAL ROAD SIGNALLING",
          "19thContractorName": "ESKAVATORI COMPANY",
          "19thProjectPeriod": "2014 - 2023",
          "19thProjectAmount": "180,000.00",

          "20thProjectName": "VARIOUS PROJECTS FOR VERTICAL AND HORIZONTAL ROAD SIGNALLING",
          "20thContractorName": "PAPENBURG & ADRIANI COMPANY",
          "20thProjectPeriod": "2015 - 2017",
          "20thProjectAmount": "100,200.00",

          "21thProjectName": "VARIOUS PROJECTS FOR VERTICAL AND HORIZONTAL ROAD SIGNALLING",
          "21thContractorName": "ALKO IMPEX",
          "21thProjectPeriod": "2015 - 2015",
          "21thProjectAmount": "14,600.00",
          
          "22thProjectName": "VARIOUS PROJECTS FOR VERTICAL AND HORIZONTAL ROAD SIGNALLING",
          "22thContractorName": "N.P.SH BAGERI",
          "22thProjectPeriod": "2015 - 2023",
          "22thProjectAmount": "150,000.00",

          "23thProjectName": "VARIOUS PROJECTS FOR VERTICAL AND HORIZONTAL ROAD SIGNALLING",
          "23thContractorName": "TALI SHPK",
          "23thProjectPeriod": "2015 - 2023",
          "23thProjectAmount": "55,000.00",

          "24thProjectName": "VARIOUS PROJECTS FOR VERTICAL AND HORIZONTAL ROAD SIGNALLING",
          "24thContractorName": "I.SEFERI SH.P.K",
          "24thProjectPeriod": "2016 - 2020",
          "24thProjectAmount": "18,500.00",

          "25thProjectName": "HORIZONTAL AND VERTICAL SIGNALLING FOR PRIZREN BYPASS, LOT 2",
          "25thContractorName": "KAG ASPHALT CO. SH.P.K",
          "25thProjectPeriod": "2016 - 2016",
          "25thProjectAmount": "8,200.00",

          "26thProjectName": "SUPPLY AND INSTALLMENT OF ROAD GUARDRAILS IN SHALË AND BAJGORE VILLAGE",
          "26thContractorName": "ILEA-GR SH.P.K",
          "26thProjectPeriod": "2019 - 2019",
          "26thProjectAmount": "16,700.00",

          "27thProjectName": "VARIOUS PROJECTS FOR VERTICAL AND HORIZONTAL ROAD SIGNALLING",
          "27thContractorName": "RAHOVICA COMERCE",
          "27thProjectPeriod": "2019 - 2023",
          "27thProjectAmount": "44,300.00",

          "28thProjectName": "SUPPLY AND INSTALLMENT OF ROAD GUARDRAILS IN VARIOUS PROJECTS",
          "28thContractorName": "GËRLICA COMPANY",
          "28thProjectPeriod": "2019 - 2020",
          "28thProjectAmount": "85,200.00",

          "29thProjectName": "SUPPLY AND INSTALLMENT OF ROAD GUARDRAILS IN VETERNIK, PRISHTINË",
          "29thContractorName": "BENI COM SHPK",
          "29thProjectPeriod": "2020 - 2020",
          "29thProjectAmount": "22,500.00",

          "30thProjectName": "SUPPLY AND INSTALLMENT OF ROAD GUARDRAILS ON THE ROAD MILLOSHEVË - VUSHTRRI, LOT 1",
          "30thContractorName": "TREMA ENGINEERING",
          "30thProjectPeriod": "2020 - 2020",
          "30thProjectAmount": "188,600.00",

          "31thProjectName": "HORIZONTAL AND VERTICAL ROAD SIGNALLING FOR THE NATIONAL, REGIONAL AND CONNECTING ROADS LOT 4",
          "31thContractorName": "MINISTRY OF INFRASTRUCTURE",
          "31thProjectPeriod": "2022 - 2023",
          "31thProjectAmount": "362,000.00",

          "32thProjectName": "HORIZONTAL AND VERTICAL ROAD SIGNALLING FOR THE NATIONAL, REGIONAL AND CONNECTING ROADS LOT 5",
          "32thContractorName": "MINISTRY OF INFRASTRUCTURE",
          "32thProjectPeriod": "2022 - 2023",
          "32thProjectAmount": "305,700.00",

          "33thProjectName": "HORIZONTAL AND VERTICAL ROAD SIGNALLING FOR MAIN, SECONDARY ROADS AND PARKING OF PRISHTINA MALL",
          "33thContractorName": "PRISHTINA MALL",
          "33thProjectPeriod": "2022 - 2023",
          "33thProjectAmount": "150,000.00",

          "34thProjectName": "HORIZONTAL AND VERTICAL ROAD SIGNALLING FOR THE NATIONAL, REGIONAL AND CONNECTING ROADS LOT 5, FERIZAJ REGION",
          "34thContractorName": "MINISTRY OF INFRASTRUCTURE",
          "34thProjectPeriod": "2023 - 2026",
          "34thProjectAmount": "964,000.00",
        }
      },
      alb: {
        translation: {
          "Home": "Ballina",
          "About": "Rreth Nesh",
          "Values": "Vlerat tona",
          "Services": "Shërbimet",
          "Projects": "Projektet",
          "Galery": "Galeria",
          "Contact": "Kontakti",
          "Welcome": "Mirë se vini",
          "WelcomeDescription": "Faleminderit për interesin e treguar për profilin e punës të kompanisë VIA. Ky web është krijuar për të dhënë një prezantim të VIA-s, shërbimet që ne ofroj-më dhe tiparet tona dalluese dhe konkuruese.Shpresojmë të kemi mundësinë të takojmë klientët tanë personalisht për të zhvilluar një marrëdhënie ndërvepruese së bashku në të ardhmen.",
          "SecondWelcomeDescription": "Që prej themelimit tonë në vitin 2014 ne kemi punuar për të krijuar një kompani me profil sa më të besueshëm, duke ndërtuar marrëdhënie afat-gjata me klientët tanë, stafin, furnizuesit dhe kontraktuesit. Dëshirojmë të falenderojmë të gjithë ata që kanë kontribuar në krijimin dhe zhvillimin e VIA-s ndër vite dhe faleminderit juve për interesin e treguar për kompaninë tonë dhe ju mirëpresim në bashkëpunime të mundshme në të ardhmen.",
          "Whoweare" : "RRETH NESH",
          "FirstAboutUsDescription": "VIA është një kompani ndërtimi e themeluar në vitin 2014. Emri i saj rrjedh nga latinishtja, fjala VIA e cila do të thotë; rrugë apo drejtim, domethënie kjo e cila njëherazi paraqet vizionin e kompanisë: “Rruga drejt së ardhmes”.",
          "SecondAboutUsDescription": "VIA është një kompani me pronësi private e themeluar pas një eksperience 16 vjeçare si pjesë e kompanisë EUROKOS, kompani e cila edhe sot mbetet partnere e jona kryesore. Zyra qëndrore e VIA-s ndodhet në Gërlicë, Ferizaj, RKS. Me përvojën tonë në projektet e ndërtimit, mirëmbajtjes, sinjalizimit dhe prodhimit, kemi arritur që për një kohë të shkurtër të fitojmë besueshmëri dhe vlerësim. Që në fillesat tona në vitin 2014, kemi zbatuar projekte të cilat gjithmonë kanë lënë pas gjurmë suksesi. Vizioni, guximi dhe ekspertiza janë elementet kyçe të suksesit në të gjithë fushat ku ne veprojmë.",
          "Ourvalues": "Vlerat tona",
          "Ethics": "Etika",
          "EthicsDescription": "Në kompaninë tonë ne i qëndrojmë besnikë vlerave tona themeluese të ofrimit të një pune cilësore, të ndershme dhe të palodhur. Ne përpiqemi t‘i përmbahemi standardeve më të larta etike në industri dhe jemi bërë pjesë e industrisë së ndërtimit si një biznes i besueshëm duke u identifikuar vazhdimisht me integritetin, standardet e larta dhe cilësinë e shërbimit dhe vëmendjen personale për klientët.",
          "Staff": "Stafi",
          "StaffDescription": "Ne jemi të vetëdijshëm se aseti ynë kryesor janë njerëzit tanë dhe se një kompani me të vërtetë e suksesshme duhet të mundësojë një mjedis pune shpërblyes për stafin e saj.Kompania jonë është e mirë-strukturuar dhe arrin të sigurojë mundësi të mjaftueshme dhe inkurajim për ekipin tonë për të arritur potencialin e tyre optimal.E njëjta bazë ndër vite e stafit menagjues, inxhinierëve, teknikëve dhe punëtorëve në terren, të cilët kanë zhvilluar kapacitetet e tyre bashkë me kompaninë që nga fillimet e saj dhe të cilët janë shtuar ndër vite, ka bërë që kompania të ecë me hapa të shpejtë në zhvillimin e saj. Ne besojmë se respekti i ndërsjellë përbën bazën e suksesit tonë",
          "Service": "Shërbimet",
          "FirstService": "Rrugët dhe urat",
          "SecondService": "MIRËMBAJTJE VERORE & DIMËRORE",
          "ThirdService": "Sinjalizim rrugor",
          "FourthService": "NDËRTIM",
          "FifthService": "Prodhim",
          "CoreWork": "Bërthama e punës",
          "Safety": "Siguria",
          "Improvement": "PËRMIRËSIM I VAZHDUESHËM",
          "SafetyDescription": "Siguria është përgjegjësi e çdo punonjësi. Në VIA çdokush ka arritur të mësojë që aksidentet parandalohen me anë të planifikimit të kujdesshëm, trajnimit të duhur dhe përpjekjeve bashkëpunuese në të gjitha fushat e punëve tona. E gjithë puna jonë kryhet në mënyrën më të sigurt të mundshme.",
          "ImprovementDescription": "VIA ka krijuar një traditë të performancës së shkëlqyer, bazuar në një angazhim për të kryer punime brenda buxhetit dhe sipas specifikimeve. Kompania ka arritur të identifikohet si një nga kompanitë më të vlerësuara duke përmirësuar vazhdimisht cilësinë e produkteve të saj të punës dhe mundësive që janë në dispozicion për punonjësit tanë.",
          "SecondImprovementDescription": "Dëshira dhe angazhimi për të përmirësuar gjithçka çka ne bëjmë është me shumë rëndësi për VIA-n. Ky përkushtim i palëkundur drejt progresit është ai që zgjeron fokusin e biznesit tonë dhe lejon punonjësit tanë të ekzaminojnë plotësisht çdo aspekt të performancës së tyre në VIA.",
          "ThirdImprovementDescription": "Përmes këtij procesi, ne mbetemi të vendosur në traditën tonë për të ofruar performancë të lartë, vëmendje ndaj çështjeve të sigurisë, kënaqësi dhe përmbushje e nevojave të punonjësve tanë.",
          "ProjectsPortfolio": "Portfolio e Projekteve",
          "ProjectName": "Emri i Projektit",
          "Contractor": "Kontraktuesi",
          "Period": "Periudha",
          "Amount": "Vlera",
          "ProjectGalery": "Galeria e Projekteve",

          "1stProjectName": "NDËRTIMI I RRUGËVE LIDHËSE, PLATFORMAVE, RRUGËT E TURBINAVE FAZA I, II & III, KITKË (FERMA E ENERGJISË ME ERË)",
          "1stContractorName": "NTP EUROKOS shpk",
          "1stProjectPeriod": "2016-2018",
          "1stProjectAmount": "394,000.00",

          "2ndProjectName": "NDËRTIMI I HIDROCENTRALIT BRODI 3, DRAGASH",
          "2ndContractorName": "NTP EUROKOS shpk",
          "2ndProjectPeriod": "2018-2019",
          "2ndProjectAmount": "414,000.00",

          "3rdProjectName": "NDËRTIMI I RRUGËVE LIDHËSE DHE HAPJA E KANALIT NËNTOKËSOR PËR SHTRIRJEN E KABLLIT TË TENSIONIT TË LARTË PËR HIDROCENTRALIN, LEPENC",
          "3rdContractorName": "HIDROENERGJI shpk",
          "3rdProjectPeriod": "2018-2019",
          "3rdProjectAmount": "344.000.00",

          "4thProjectName": "ZGJERIMI I RRUGËS NACIONALE N 9.1, SEGMENTI DOLLC - GJAKOVË - LOT III",
          "4thContractorName": "MINISTRIA E INFRASTRUKTURËS & TRANSPORTIT",
          "4thProjectPeriod": "2019 - 2021",
          "4thProjectAmount": "4,393,000.00",

          "5thProjectName": "SANIMI I RRËSHQITËZËS NË RRUGËN HANI I ELEZIT - GORANCË",
          "5thContractorName": "MINISTRIA E INFRASTRUKTURËS & TRANSPORTIT",
          "5thProjectPeriod": "2019 - 2020",
          "5thProjectAmount": "1,514,000.00",

          "6thProjectName": "NDËRTIMI I OBJEKTEVE TË NDRYSHME USHTARAKE, NDËRTIMI I ANEKSIT TË OBJEKTIT TË MKAB NË KAZERMËN E FSK-SË NË POMOZOTIN",
          "6thContractorName": "MINISTRIA E MBROJTJES",
          "6thProjectPeriod": "2019 - 2020",
          "6thProjectAmount": "223,000.00",

          "7thProjectName": "RINDËRTIMI I NJËSIVE TË BANIMIT NË NDËRTESA (PALLAT) PËR ZONËN E RE PËR ZHVILLIM VORË.",
          "7thContractorName": "FONDI SHQIPTAR I ZHVILLIMIT",
          "7thProjectPeriod": "2021 - 2024",
          "7thProjectAmount": "3,250,000.00",

          "8thProjectName": "REHABILITIMI I RRUGËS QAMIL ILAZI DHE SALI BAJRA",
          "8thContractorName": "KOMUNA KAÇANIK",
          "8thProjectPeriod": "2021 - 2022",
          "8thProjectAmount": "259,000.00",

          "9thProjectName": "NDËRTIMI I GABIONAVE NË RRUGËN MITROVICË - SHALË E BAJGORËS",
          "9thContractorName": "TALI shpk",
          "9thProjectPeriod": "2021 - 2022",
          "9thProjectAmount": "69,000.00",

          "10thProjectName": "NDËRTIMI I STAZAVE DHE POLIGONIT TË QITJES USHTARAKE NË KAZERMEN E FSK-SË NË FERIZAJ",
          "10thContractorName": "MINISTRIA E MBROJTJES",
          "10thProjectPeriod": "2022 - 2022",
          "10thProjectAmount": "144,000.00",

          "11thProjectName": "DËRTIMI I OBJEKTIT PËR NDËRMARRJEN KOMUNALE",
          "11thContractorName": "K.K SKENDERAJ",
          "11thProjectPeriod": "2022 - 2023",
          "11thProjectAmount": "248,000.00",

          "12thProjectName": "NDËRTIMI I POLIGONEVE SPORTIVE NË FSHATIN VAJNIKE DHE LUBAVEC NË HAPËSIRAT E SHKOLLAVE TË FSHATIT",
          "12thContractorName": "K.K SKENDERAJ",
          "12thProjectPeriod": "2022 - 2022",
          "12thProjectAmount": "59,000.00",

          "13thProjectName": "MIRËMBAJTJA VERORE DHE DIMËRORE E RRUGËVE NACIONALE DHE REGJIONALE TË REPUBLIKËS SË KOSOVËS, REGJIONI FERIZAJ 1",
          "13thContractorName": "MINISTRIA E INFRASTRUKTURËS & TRANSPORTIT",
          "13thProjectPeriod": "2014 - 2018",
          "13thProjectAmount": "3,032,000.00",

          "14thProjectName": "MIRËMBAJTJA VERORE DHE DIMËRORE E RRUGËVE NACIONALE DHE REGJIONALE TË KOSOVËS , REGJIONI FERIZAJ 1",
          "14thContractorName": "MINISTRIA E INFRASTRUKTURËS & TRANSPORTIT",
          "14thProjectPeriod": "2018 - 2021",
          "14thProjectAmount": "3,650.000.00",

          "15thProjectName": "MIRËMBAJTJA VERORE DHE DIMËRORE E RRUGËVE LOKALE",
          "15thContractorName": "KOMUNA KAÇANIK",
          "15thProjectPeriod": "2021 - 2022",
          "15thProjectAmount": "260,000.00",

          "16thProjectName": "MBARËVAJTJA E TRAFIKUT NË RRUGËN NACIONALE N2, KAÇANIK - HANI I ELEZIT",
          "16thContractorName": "MINISTRIA E INFRASTRUKTURËS",
          "16thProjectPeriod": "2021 - 2023",
          "16thProjectAmount": "70,100.00",

          "17thProjectName": "MIRËMBAJTJA VERORE DHE DIMËRORE E AUTOSTRADËS NACIONALE „ARBËR XHAFERI“ LOT 3",
          "17thContractorName": "MINISTRIA E INFRASTRUKTURËS",
          "17thProjectPeriod": "2022 - 2025",
          "17thProjectAmount": "2,100.000.00",

          "18thProjectName": "MIRËMBAJTJA VERORE DHE DIMËRORE E RRUGËVE NACIONALE DHE REGJIONALE TË KOSOVËS, REGJIONI FERIZAJ 1 & GJILAN 2",
          "18thContractorName": "MINISTRIA E INFRASTRUKTURËS",
          "18thProjectPeriod": "2022 - 2026",
          "18thProjectAmount": "4,180.000.00",

          "19thProjectName": "PROJEKTE TË NDRYSHME TË SINJALIZIMIT VERIKAL DHE HORIZONTAL TË RRUGËVE",
          "19thContractorName": "ESKAVATORI COMPANY",
          "19thProjectPeriod": "2014 - 2023",
          "19thProjectAmount": "180,000.00",

          "20thProjectName": "PROJEKTE TË NDRYSHME TË SINJALIZIMIT VERIKAL DHE HORIZONTAL TË RRUGËVE",
          "20thContractorName": "PAPENBURG & ADRIANI COMPANY",
          "20thProjectPeriod": "2015 - 2017",
          "20thProjectAmount": "100,200.00",

          "21thProjectName": "PROJEKTE TË NDRYSHME TË SINJALIZIMIT VERIKAL DHE HORIZONTAL TË RRUGËVE",
          "21thContractorName": "ALKO IMPEX",
          "21thProjectPeriod": "2015 - 2015",
          "21thProjectAmount": "14,600.00",

          "22thProjectName": "PROJEKTE TË NDRYSHME TË SINJALIZIMIT VERIKAL DHE HORIZONTAL TË RRUGËVE",
          "22thContractorName": "N.P.SH BAGERI",
          "22thProjectPeriod": "2015 - 2023",
          "22thProjectAmount": "150,000.00",

          "23thProjectName": "PROJEKTE TË NDRYSHME TË SINJALIZIMIT VERIKAL DHE HORIZONTAL TË RRUGËVE",
          "23thContractorName": "TALI SHPK",
          "23thProjectPeriod": "2015 - 2023",
          "23thProjectAmount": "55,000.00",

          "24thProjectName": "PROJEKTE TË NDRYSHME TË SINJALIZIMIT VERIKAL DHE HORIZONTAL TË RRUGËVE",
          "24thContractorName": "I.SEFERI SH.P.K",
          "24thProjectPeriod": "2016 - 2020",
          "24thProjectAmount": "18,500.00",

          "25thProjectName": "SINJALIZIMI HORIZONTAL DHE VERTIKAL NË TRANZIT I VJETËR PRIZREN LLOT 2",
          "25thContractorName": "KAG ASPHALT CO. SH.P.K",
          "25thProjectPeriod": "2016 - 2016",
          "25thProjectAmount": "8,200.00",

          "26thProjectName": "FURNIZIM DHE VENDOSJA E PAHIVE ELASTIKE NË FSHATIN SHALË E BAJGORES",
          "26thContractorName": "ILEA-GR SH.P.K",
          "26thProjectPeriod": "2019 - 2019",
          "26thProjectAmount": "16,700.00",

          "27thProjectName": "PROJEKTE TË NDRYSHME TË SINJALIZIMIT VERIKAL DHE HORIZONTAL TË RRUGËVE",
          "27thContractorName": "RAHOVICA COMERCE",
          "27thProjectPeriod": "2019 - 2023",
          "27thProjectAmount": "44,300.00",

          "28thProjectName": "SUPPLY AND INSTALLMENT OF ROAD GUARDRAILS IN VARIOUS PROJECTS",
          "28thContractorName": "GËRLICA COMPANY",
          "28thProjectPeriod": "2019 - 2020",
          "28thProjectAmount": "85,200.00",

          "29thProjectName": "SUPPLY AND INSTALLMENT OF ROAD GUARDRAILS ON THE ROAD MILLOSHEVË - VUSHTRRI, LOT 1",
          "29thContractorName": "BENI COM SHPK",
          "29thProjectPeriod": "2020 - 2020",
          "29thProjectAmount": "22,500.00",

          "30thProjectName": "SUPPLY AND INSTALLMENT OF ROAD GUARDRAILS ON THE ROAD MILLOSHEVË - VUSHTRRI, LOT 1",
          "30thContractorName": "TREMA ENGINEERING",
          "30thProjectPeriod": "2020 - 2020",
          "30thProjectAmount": "188,600.00",

          "31thProjectName": "SINJALIZIMI I RRUGËVE NACIONALE, RAJONALE, RRUGËVE LIDHËSE DHE PAJISJET RRUGORE LOT 4",
          "31thContractorName": "MINISTRIA E INFRASTRUKTURËS",
          "31thProjectPeriod": "2022 - 2023",
          "31thProjectAmount": "362,000.00",

          "32thProjectName": "SINJALIZIMI I RRUGËVE NACIONALE, RAJONALE, RRUGËVE LIDHËSE DHE PAJISJET RRUGORE LOT 5",
          "32thContractorName": "MINISTRIA E INFRASTRUKTURËS",
          "32thProjectPeriod": "2022 - 2023",
          "32thProjectAmount": "305,700.00",

          "33thProjectName": "SINJALIZMI HORIZONTAL DHE VERTIKAL I RRUGËVE KRYESORE, SEKONDARE DHE PARKINGJEVE NË PRISHTINA MALL",
          "33thContractorName": "PRISHTINA MALL",
          "33thProjectPeriod": "2022 - 2023",
          "33thProjectAmount": "150,000.00",

          "34thProjectName": "SINJALIZIMI I RRUGËVE NACIONALE, RAJONALE, RRUGËVE LIDHËSE DHE PAJISJET RRUGORE LOT 5, REGJIONI FERIZAJ",
          "34thContractorName": "MINISTRIA E INFRASTRUKTURËS",
          "34thProjectPeriod": "2023 - 2026",
          "34thProjectAmount": "964,000.00",
        }
      }
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false 
    }
  });

export default i18n;
