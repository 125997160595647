import React from "react";
// import image1 from "../assets/32.png"
// import image2 from "../assets/33.png"
// import image3 from "../assets/35.png"
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useTranslation } from "react-i18next";
import image1 from "../assets/Galery1.jpg"
import image2 from "../assets/Galery2.jpg"
import image3 from "../assets/Galery3.jpg"
import image4 from "../assets/Galery4.jpg"
import image5 from "../assets/Galery5.jpg"
import image6 from "../assets/Galery6.jpg"
import logo from "../assets/logo.png";

const items = [
  { image: image4, },
  { image: image5, },
  { image: image6 },
  { image: image2 },

]

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const Banner = () => {
  const { t } = useTranslation();

  return (
    <>
    <div className="w-full border-b-4 border-white lg:h-screen flex flex-col lg:flex-row relative">
    <div className="hidden lg:block lg:px-8 lg:w-1/2 lg:pr-4 bg-[#333332]">
      <Carousel
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        showDots={true}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
      >
        {items?.map((item, index) => (
          <div key={index} className='carousel-item lg:mt-16 p-6 lg:p-0'>
            <img src={item.image} alt={item.title} className='w-full lg:h-[500px] 2xl:h-[800px] object-cover' />
          </div>
        ))}
      </Carousel>
      </div>
      <div className="lg:w-1/2 flex px-4 py-4 lg:py-0 lg:px-10 items-center justify-center bg-[#333332]">
        <p className="text-white justify-center items-center">
        
            <>
              <p className="font-custom uppercase text-2xl 2xl:text-4xl">
                {t("Welcome")}
              </p>
              <p className="font-custom1 text-sm 2xl:text-lg mt-4 lg:pr-4 text-justify tracking-tighter">
              {t("WelcomeDescription")} 
              </p>
              <p className="font-custom1 text-sm 2xl:text-lg mt-4 lg:pr-4 text-justify tracking-tighter">
              {t("SecondWelcomeDescription")}
              </p>
            </>
     
        </p>
      </div>
      <div className="lg:hidden lg:w-1/2 lg:pr-4 bg-[#333332]">
      <Carousel
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        showDots={true}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        removeArrowOnDeviceType={['tablet', 'mobile', 'desktop']}
      >
        {items?.map((item, index) => (
          <div key={index} className='carousel-item lg:mt-16 p-4 lg:p-0'>
            <img src={item.image} alt={item.title} className='w-full lg:h-[500px] 2xl:h-[800px] object-cover rounded-[6px]' />
          </div>
        ))}
      </Carousel>
      </div>
    </div>

</>
  );
};

export default Banner;
