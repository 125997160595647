import React, { useEffect, useState } from 'react';
import serviceImage from "../assets/Picture95.png"
import { useTranslation } from 'react-i18next';

const Services = () => {
  const { t } = useTranslation();

  return (
    <div className='lg:h-screen relative py-[100px] border-t-4 border-white bg-[#313232]'  id="services">
      <div className='px-0 w-full h-full flex items-center '>
        <div className='text-right w-full'>
            <div className='text-center'>
                <img src={serviceImage} alt='' className='mx-4 w-28 h-28 object-cover' />
              <p className='mt-10 font-custom uppercase text-black p-5 text-2xl 2xl:text-4xl bg-white'>
                {t('Services')}
              </p>
              <div className='text-left justify-center items-center flex'>
                <div className='font-custom1 uppercase text-sm rounded-md tracking-tighter text-white mt-4 p-5'>
                <p className='font-custom text-lg lg:text-xl mt-4 hover:mx-3 hover:duration-300 cursor-pointer hover:text-red-900'><span>&rarr; </span>
                         {t('FirstService')}
                    </p>
                    <p className='font-custom text-lg lg:text-xl mt-4 hover:mx-3 hover:duration-300 cursor-pointer hover:text-red-900'><span>&rarr; </span>
                         {t('SecondService')}
                    </p>
                    <p className='font-custom text-lg lg:text-xl mt-4 hover:mx-3 hover:duration-300 cursor-pointer hover:text-red-900'><span>&rarr; </span>
                         {t('ThirdService')}
                    </p>
                    <p className='font-custom text-lg lg:text-xl mt-4 hover:mx-3 hover:duration-300 cursor-pointer hover:text-red-900'><span>&rarr; </span>
                         {t('FourthService')}
                    </p>
                    <p className='font-custom text-lg lg:text-xl mt-4 hover:mx-3 hover:duration-300 cursor-pointer hover:text-red-900'><span>&rarr; </span>
                         {t('FifthService')}
                    </p>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
