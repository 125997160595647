import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Roads = () => {
    const { t } = useTranslation();
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(7);
    const [selectedCategory, setSelectedCategory] = useState('All');

    const projectData = [ 
        {
            id: "07",
            category: "ROADS, BRIDGES & CONSTRUCTION",
            titleName: t("7thProjectName"),
            contractorName: t('7thContractorName'),
            periods: t('7thProjectPeriod'),
            priceProjectName: t('7thProjectAmount')
        },
        {
            id: "01",
            category: "ROADS, BRIDGES & CONSTRUCTION",
            titleName: t("1stProjectName"),
            contractorName: t('1stContractorName'),
            periods: t('1stProjectPeriod'),
            priceProjectName: t('1stProjectAmount')
        },
        {
            id: "02",
            category: "ROADS, BRIDGES & CONSTRUCTION",
            titleName: t("2ndProjectName"),
            contractorName: t('2ndContractorName'),
            periods: t('2ndProjectPeriod'),
            priceProjectName: t('2ndProjectAmount')
        },
        {
            id: "03",
            category: "ROADS, BRIDGES & CONSTRUCTION",
            titleName: t("3rdProjectName"),
            contractorName: t('3rdContractorName'),
            periods: t('3rdProjectPeriod'),
            priceProjectName: t('3rdProjectAmount')
        },
        {
            id: "04",
            category: "ROADS, BRIDGES & CONSTRUCTION",
            titleName: t("4thProjectName"),
            contractorName: t('4thContractorName'),
            periods: t('4thProjectPeriod'),
            priceProjectName: t('4thProjectAmount')
        },
        {
            id: "05",
            category: "ROADS, BRIDGES & CONSTRUCTION",
            titleName: t("5thProjectName"),
            contractorName: t('5thContractorName'),
            periods: t('5thProjectPeriod'),
            priceProjectName: t('5thProjectAmount')
        },
        {
            id: "06",
            category: "ROADS, BRIDGES & CONSTRUCTION",
            titleName: t("6thProjectName"),
            contractorName: t('6thContractorName'),
            periods: t('6thProjectPeriod'),
            priceProjectName: t('6thProjectAmount')
        },
        {
            id: "08",
            category: "ROADS, BRIDGES & CONSTRUCTION",
            titleName: t("8thProjectName"),
            contractorName: t('8thContractorName'),
            periods: t('8thProjectPeriod'),
            priceProjectName: t('8thProjectAmount')
        },
        {
            id: "09",
            category: "ROADS, BRIDGES & CONSTRUCTION",
            titleName: t("9thProjectName"),
            contractorName: t('9thContractorName'),
            periods: t('9thProjectPeriod'),
            priceProjectName: t('9thProjectAmount')
        },
        {
            id: "10",
            category: "ROADS, BRIDGES & CONSTRUCTION",
            titleName: t("10thProjectName"),
            contractorName: t('10thContractorName'),
            periods: t('10thProjectPeriod'),
            priceProjectName: t('10thProjectAmount')
        },
        {
            id: "11",
            category: "ROADS, BRIDGES & CONSTRUCTION",
            titleName: t("11thProjectName"),
            contractorName: t('11thContractorName'),
            periods: t('11thProjectPeriod'),
            priceProjectName: t('11thProjectAmount')
        },
        {
            id: "12",
            category: "ROADS, BRIDGES & CONSTRUCTION",
            titleName: t("12thProjectName"),
            contractorName: t('12thContractorName'),
            periods: t('12thProjectPeriod'),
            priceProjectName: t('12thProjectAmount')
        },
        {
            id: "13",
            category: "SUMMER & WINTER ROAD MAINTENANCE",
            titleName: t("13thProjectName"),
            contractorName: t('13thContractorName'),
            periods: t('13thProjectPeriod'),
            priceProjectName: t('13thProjectAmount')
        },
        {
            id: "14",
            category: "SUMMER & WINTER ROAD MAINTENANCE",
            titleName: t("14thProjectName"),
            contractorName: t('14thContractorName'),
            periods: t('14thProjectPeriod'),
            priceProjectName: t('14thProjectAmount')
        },
        {
            id: "15",
            category: "SUMMER & WINTER ROAD MAINTENANCE",
            titleName: t("15thProjectName"),
            contractorName: t('15thContractorName'),
            periods: t('15thProjectPeriod'),
            priceProjectName: t('15thProjectAmount')
        },
        {
            id: "16",
            category: "SUMMER & WINTER ROAD MAINTENANCE",
            titleName: t("16thProjectName"),
            contractorName: t('16thContractorName'),
            periods: t('16thProjectPeriod'),
            priceProjectName: t('16thProjectAmount')
        },
        {
            id: "17",
            category: "SUMMER & WINTER ROAD MAINTENANCE",
            titleName: t("17thProjectName"),
            contractorName: t('17thContractorName'),
            periods: t('17thProjectPeriod'),
            priceProjectName: t('17thProjectAmount')
        },
        {
            id: "18",
            category: "SUMMER & WINTER ROAD MAINTENANCE",
            titleName: t("18thProjectName"),
            contractorName: t('18thContractorName'),
            periods: t('18thProjectPeriod'),
            priceProjectName: t('18thProjectAmount')
        },
        {
            id: "19",
            category: "HORIZONTAL & VERTICAL SIGNALLING",
            titleName: t("19thProjectName"),
            contractorName: t('19thContractorName'),
            periods: t('19thProjectPeriod'),
            priceProjectName: t('19thProjectAmount')
        },
        {
            id: "20",
            category: "HORIZONTAL & VERTICAL SIGNALLING",
            titleName: t("20thProjectName"),
            contractorName: t('20thContractorName'),
            periods: t('20thProjectPeriod'),
            priceProjectName: t('20thProjectAmount')
        },
        {
            id: "21",
            category: "HORIZONTAL & VERTICAL SIGNALLING",
            titleName: t("21thProjectName"),
            contractorName: t('21thContractorName'),
            periods: t('21thProjectPeriod'),
            priceProjectName: t('21thProjectAmount')
        },
        {
            id: "22",
            category: "HORIZONTAL & VERTICAL SIGNALLING",
            titleName: t("22thProjectName"),
            contractorName: t('22thContractorName'),
            periods: t('22thProjectPeriod'),
            priceProjectName: t('22thProjectAmount')
        },
        {
            id: "23",
            category: "HORIZONTAL & VERTICAL SIGNALLING",
            titleName: t("23thProjectName"),
            contractorName: t('23thContractorName'),
            periods: t('23thProjectPeriod'),
            priceProjectName: t('23thProjectAmount')
        },
        {
            id: "24",
            category: "HORIZONTAL & VERTICAL SIGNALLING",
            titleName: t("24thProjectName"),
            contractorName: t('24thContractorName'),
            periods: t('24thProjectPeriod'),
            priceProjectName: t('24thProjectAmount')
        },
        {
            id: "25",
            category: "HORIZONTAL & VERTICAL SIGNALLING",
            titleName: t("25thProjectName"),
            contractorName: t('25thContractorName'),
            periods: t('25thProjectPeriod'),
            priceProjectName: t('25thProjectAmount')
        },
        {
            id: "26",
            category: "HORIZONTAL & VERTICAL SIGNALLING",
            titleName: t("26thProjectName"),
            contractorName: t('26thContractorName'),
            periods: t('26thProjectPeriod'),
            priceProjectName: t('26thProjectAmount')
        },
        {
            id: "27",
            category: "HORIZONTAL & VERTICAL SIGNALLING",
            titleName: t("27thProjectName"),
            contractorName: t('27thContractorName'),
            periods: t('27thProjectPeriod'),
            priceProjectName: t('27thProjectAmount')
        },
        {
            id: "28",
            category: "HORIZONTAL & VERTICAL SIGNALLING",
            titleName: t("28thProjectName"),
            contractorName: t('28thContractorName'),
            periods: t('28thProjectPeriod'),
            priceProjectName: t('28thProjectAmount')
        },
        {
            id: "29",
            category: "HORIZONTAL & VERTICAL SIGNALLING",
            titleName: t("29thProjectName"),
            contractorName: t('29thContractorName'),
            periods: t('29thProjectPeriod'),
            priceProjectName: t('29thProjectAmount')
        },
        {
            id: "30",
            category: "HORIZONTAL & VERTICAL SIGNALLING",
            titleName: t("30thProjectName"),
            contractorName: t('30thContractorName'),
            periods: t('30thProjectPeriod'),
            priceProjectName: t('30thProjectAmount')
        },
        {
            id: "31",
            category: "HORIZONTAL & VERTICAL SIGNALLING",
            titleName: t("31thProjectName"),
            contractorName: t('31thContractorName'),
            periods: t('31thProjectPeriod'),
            priceProjectName: t('31thProjectAmount')
        },
        {
            id: "32",
            category: "HORIZONTAL & VERTICAL SIGNALLING",
            titleName: t("32thProjectName"),
            contractorName: t('32thContractorName'),
            periods: t('32thProjectPeriod'),
            priceProjectName: t('32thProjectAmount')
        },
        {
            id: "33",
            category: "HORIZONTAL & VERTICAL SIGNALLING",
            titleName: t("33thProjectName"),
            contractorName: t('33thContractorName'),
            periods: t('33thProjectPeriod'),
            priceProjectName: t('33thProjectAmount')
        },
        {
            id: "34",
            category: "HORIZONTAL & VERTICAL SIGNALLING",
            titleName: t("34thProjectName"),
            contractorName: t('34thContractorName'),
            periods: t('34thProjectPeriod'),
            priceProjectName: t('34thProjectAmount')
        }
    ];

    const filteredProjects = selectedCategory === 'All' ? projectData : projectData.filter(project => project.category === selectedCategory);

    // Change page
    const paginate = pageNumber => {
        setCurrentPage(pageNumber);
    };

    // Calculate the index of the first and last item of the current page based on filtered data
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentProjects = filteredProjects.slice(indexOfFirstItem, indexOfLastItem);

    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value);
        setCurrentPage(1); // Reset pagination when category changes
    };

    return (
        <div className='absolute top-0 p-[2px] lg:px-8 w-full h-full flex items-center lg:justify-center overflow-x-auto'>
            <div className="h-fulls inline-block shadow-sm rounded-lg">
                <div className="my-4">
                    <select
                        id="categoryFilter"
                        className="px-2 py-1 lg:w-fit w-full h-12 bg-[#313232] text-white"
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                    >
                        <option value="All">{t('All Categories')}</option>
                        <option value="ROADS, BRIDGES & CONSTRUCTION">{t('Roads, Bridges & Construction')}</option>
                        <option value="SUMMER & WINTER ROAD MAINTENANCE">{t('Summer & Winter Road Maintenance')}</option>
                        <option value="HORIZONTAL & VERTICAL SIGNALLING">{t('Horizontal & Vertical Signalling')}</option>
                    </select>
                </div>
                <table className="mt-6 bg-[#313232] bg-opacity-70">
                    <thead>
                        <tr className='uppercase text-white'>
                            <th className="border-l-0 lg:px-4 py-3 bg-[#313232] text-center lg:text-left text-sm 2xl:text-lg lg:text-base">{t('ProjectName')}</th>
                            <th className="border-t-0 px-1 border-l lg:px-4 py-3 bg-[#313232] text-center lg:text-left text-sm 2xl:text-lg lg:text-base">{t('Contractor')}</th>
                            <th className="border-t-0 border-l border-r lg:px-4 py-3 bg-[#313232] text-center lg:text-left text-sm 2xl:text-lg lg:text-base">{t('Period')}</th>
                            <th className="border-r-0 px-1 lg:px-4 py-3 bg-[#313232] text-center lg:text-left text-sm 2xl:text-lg lg:text-base">{t('Amount')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentProjects.map(item => (
                            <tr className='font-custom1 text-white' key={item.id}>
                                <td className="text-center border-l-0 lg:text-left border lg:w-[45%] lg:px-4 py-2 text-[9px] lg:text-sm 2xl:text-lg">
                                    {t(item.titleName)}
                                </td>
                                <td className="text-center lg:text-left border lg:px-4 py-2 text-[9px] lg:text-sm 2xl:text-lg">
                                    {t(item.contractorName)}
                                </td>
                                <td className="text-center lg:text-left w-24 lg:w-fit border lg:px-4 py-2 text-[9px]  2xl:text-lg lg:text-sm">
                                    {t(item.periods)}
                                </td>
                                <td className="text-center px-1 lg:text-left border-r-0 border lg:px-4 py-2 text-[9px] lg:text-sm 2xl:text-lg">
                                    {t(item.priceProjectName)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {/* Pagination */}
                <div className="flex justify-center mt-4">
                    {[...Array(Math.ceil(filteredProjects.length / itemsPerPage)).keys()].map(number => (
                        <button key={number} onClick={() => paginate(number + 1)} className={`mx-1 px-3 py-1 rounded-lg ${currentPage === number + 1 ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-700'}`}>
                            {number + 1}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Roads;
