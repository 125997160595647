import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import about1 from "../assets/project1-1.png"
import about2 from "../assets/project1-2.png"
import about3 from "../assets/construction1-1.png"
import about4 from "../assets/construction1-2.png"
import about5 from "../assets/maintenance-1.png"
import about6 from "../assets/maintenance-2.png"
import about7 from "../assets/signalling-1.png"
import about8 from "../assets/signalling-2.png"
import Roads from './Roads';
import { useTranslation } from 'react-i18next';

const ProjectsOne = () => {
  const { t } = useTranslation();

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };

  return (
    <>
    <div className='bg-[#313232] border-t-4 border-white' id='projects'>
        <p className='text-white text-2xl text-center lg:text-4xl lg:text-left lg:ml-8 font-custom uppercase py-8'>
            {t('ProjectsPortfolio')}
        </p>
     </div>
    <div className='h-screen lg:h-[100%] relative'>
      <Carousel
        responsive={responsive}
        infinite
        autoPlay
        autoPlaySpeed={3000}
        keyBoardControl
        customTransition="transform 700ms ease-in-out"
        transitionDuration={700}
        containerClass="carousel-container"
        arrows={false}
        centerMode={false}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {[about1, about2, about3, about4, about5, about6, about7, about8].map((image, index) => (
          <div key={index} className='h-screen'>
            <img src={image} alt={`Imag ${index + 1}`} className='w-full h-full object-cover' />
          </div>
        ))}
      </Carousel>
      
       <Roads />
    </div>
    </>
  );
};

export default ProjectsOne;
