import React, { useState } from 'react';
import image1 from "../assets/Galery1.jpg"
import image2 from "../assets/Galery2.jpg"
import image3 from "../assets/Galery3.jpg"
import image4 from "../assets/Galery4.jpg"
import image5 from "../assets/Galery5.jpg"
import image6 from "../assets/Galery6.jpg"
import image7 from "../assets/Galery7.jpg"
import image8 from "../assets/Galery8.jpg"
import image9 from "../assets/Galery9.jpg"
import image10 from "../assets/Galery10.jpg"
import image11 from "../assets/Galery11.jpg"
import image12 from "../assets/Galery12.jpg"
import image13 from "../assets/Galery13.jpg"
import image14 from "../assets/Galery14.jpg"
import image15 from "../assets/Galery15.jpeg"
import image16 from "../assets/Galery16.JPG"
import { useTranslation } from 'react-i18next';

const initialData = [ 
    { id:1, title: "Project 1", image: image1 },
    { id:2, title: "Project 2", image: image2 },
    { id:3, title: "Project 3", image: image3 },
    { id:4, title: "Project 4", image: image4 },
    { id:5, title: "Project 5", image: image5 },
    { id:6, title: "Project 6", image: image6 },
    { id:7, title: "Project 7", image: image7 },
    { id:8, title: "Project 8", image: image8 },
    { id:9, title: "Project 9", image: image9 },
    { id:10, title: "Project 10", image: image10 },
    { id:11, title: "Project 11", image: image11 },
    { id:12, title: "Project 12", image: image12 },
    { id:13, title: "Project 13", image: image13 },
    { id:14, title: "Project 14", image: image14 },
    { id:15, title: "Project 15", image: image15 },
    { id:16, title: "Project 16", image: image16 },
]

const Gallery = () => {
    const { t } = useTranslation();
    const [showAll, setShowAll] = useState(false);
    const [visibleItems, setVisibleItems] = useState(8); // Initialize with the number of items you want to show initially

    const handleShowMore = () => {
        if (!showAll) {
            setVisibleItems(prev => prev + 4); // Increase the visible items by 4 only if not showing all items
            if (visibleItems + 4 >= initialData.length) {
                setShowAll(true); // If all items will be shown after this click, set showAll to true
            }
        } else {
            setVisibleItems(8); // Reset visible items to initial value
            setShowAll(false); // Reset showAll state
        }
    };

    return (
        <>
            <div className='bg-[#313232] border-t-4 border-white' id='projects'>
                <p className='text-white text-2xl text-center lg:text-4xl lg:text-left lg:ml-8 font-custom uppercase py-8'>
                    {t('ProjectGalery')}
                </p>
            </div>

            <div className='px-2 lg:px-8 bg-[#313232]' id='gallery'>
                <div className='grid grid-cols-2 lg:grid-cols-4 gap-4'>
                    {initialData.slice(0, showAll ? initialData.length : visibleItems).map((item, index) => (
                        <div key={index} className="relative group">
                            <img className='object-cover h-72 w-full' src={item.image} alt='' />
                            <div className="hover-info cursor-pointer absolute inset-0 bg-black bg-opacity-50 p-2 text-white opacity-0 transition-opacity duration-100 group-hover:opacity-100">
                                <div className='absolute bottom-3 left-1'>
                                    <h2 className="font-custom text-lg font-semibold">{item.title}</h2>
                                    <p className='font-custom1'>{item.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="text-center mt-4">
                    <button onClick={handleShowMore} className="bg-white text-black font-custom1 font-bold w-52 px-4 py-2 hover:bg-[#494b4b] hover:text-white hover:duration-300 hover:ease-linear">
                        {showAll ? "Show Less" : "Show More"}
                    </button>
                </div>
            </div>
        </>
    );
};

export default Gallery;
